import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/2.png"
import Placeholder from "../images/placeholder_penguin.svg"

const LECTURER = [
  {
    src: Placeholder,
    alt: "",
    personName: "Tomáš Baxa",
    text: [""],
  },
]

const KonfiguraceGa4: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper
        title="Konfigurace GA4 a její specifika"
        ogImage={OgImage}
      />

      <SyllabusHeader
        orangeText="Konfigurace GA4"
        blackText="a její specifika"
        numberOfLesson="2/13"
      ></SyllabusHeader>
      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          analytics a eventy (úvod)
          {/* <ul> */}
          <div>
            krátké zopakování základů event based modelu a jeho rozdílu oproti
            UA
          </div>
          {/* </ul> */}
          <div>ukázka rozdílu surových dat v GA4 a UA</div>
          <div>
            co je to event a jak vypadá v kontextu GA4
            <div>automaticky sbírané eventy</div>
            <div>doporučené eventy</div>
            <div>vlastní eventy</div>
            <div>doporučené parametry</div>
            <div>technické parametry</div>
            <div>vlastní parametry</div>
          </div>
          <div>
            naming eventů a parametrů
            <div>generické vs. specifické parametry</div>
          </div>
        </div>
        <div>
          základní implementace
          <div>
            gtag, GTM, connected site tags (napojení na stávající UA
            implementaci)
          </div>
          <div>
            základní nastavení
            <div>možnosti nastavení parametrů</div>
            <div>typy parametrů: user, event, string, number</div>
          </div>
          <div>
            limity GA4
            <div>naming, eventy, parametry, vlastní dimenze</div>
          </div>
        </div>

        <div>
          e-commerce implementace
          <div>doporučené eventy a parametry</div>
          <div>přechod z UA na GA4</div>
          <div>překryv scénářů událostí s jinými nástroji (především FB)</div>
        </div>

        <div>
          pokročilé implementace
          <div>cross-domain měření</div>
          <div>problematika více konfigurací GA4 na jednom webu</div>
          <div>základ měření aplikací (úplné základy)</div>
          <div>measurement protocol (rozdíly oproti SGTM)</div>
        </div>

        <div>
          debugging
          <div>GTM, wasp, GA4 debug rozhraní</div>
          <div>live ukázky</div>
          <div>
            rozbor posílaných dat
            <div>HTTP metody</div>
            <div>jak vypadá request</div>
            <div>
              stručný přehled parametrů v requestu
              <div>a. rozbor parametrů</div>
              <div>b. povinné parametry</div>
            </div>
          </div>
          <div>jaké cookies GA4 ukládají a k čemu je používají</div>
          <div>
            debugging aplikací
            <div>android debugger</div>
          </div>
          <div>testování measurement protokolu</div>
        </div>

        <div>
          nastavení samotných GA4
          <div>přístupy uživatelů </div>
          <div>architektura</div>
          <div>filtry, platební brány</div>
          <div>konfigurace proměnných, událostí a konverzí</div>
          <div>cross-domain</div>
          <div>cross-device, user id, G signals</div>
          <div>retence a &#34;GDPR&#34; funkcionalita</div>
          <div>atribuční modely</div>
          <div>
            pokročilé možnosti GA4
            <div>úpravy událostí</div>
            <div>tvorba událostí</div>
            <div>audience triggers</div>
            <div>user provided data (&#34;Enhanced conversions&#34;)</div>
          </div>
          <div>
            napojení na další systémy
            <div>napojení na BQ</div>
            <div>napojení na G Ads</div>
            <div>napojení na DS</div>
            <div>napojení na G Search Consoli</div>
          </div>
          <div>
            okrajové možnosti nastavení
            <div>session timeout</div>
            <div>cookie settings &#34;timeout&#34;</div>
          </div>
          <div>
            datový import
            <div>jaké datové importy jsou k dispozici</div>
            <div>jak fungují</div>
            <div>jak se chovají</div>
          </div>
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default KonfiguraceGa4
